import BtnCompo from "components/btn-compo";
import ImgCompo from "components/img-compo";
import Footer from "components/footer";

import React, { useEffect, useState } from "react";
import { Container, Form, InputGroup, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isAgent, setIsAgent] = useState(false);
  const [agentCode, setAgentCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [representativeName, setRepresentativeName] = useState("");
  const [representativeSurname, setRepresentativeSurname] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, [])

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (agentCode && representativeName && representativeSurname) {
      let representativeData = {
        representativeCode: agentCode,
        representativeName: representativeName,
        representativeSurname: representativeSurname
      }
      localStorage.setItem("representativeData", JSON.stringify(representativeData)); navigate("/terms-condition");
    } else { event.preventDefault(); setValidated(true); }
  }

  return (
    <Container>
      <div className="d-flex flex-column m-auto align-items-center">
        <ImgCompo
          src="/images/logo.png"
          alt="Shield Life Funeral"
          className="m-lg-b img-fluid"
          width={224}
          height={224}
        />

        {!isAgent &&
          <div>
            <div className="mb-2">
              <Button
                size="sm"
                onClick={() => setIsAgent(true)}
                className="fs-6 w-100"
              >AGENT
              </Button>
            </div>
            <div className="mb-2">
              <Button
                size="sm"

                href="/terms-condition"
                className="fs-6 w-100"
                onClick={() => { setIsAgent(false); setAgentCode(""); localStorage.removeItem("agentCode") }}
              >PERSONAL</Button>
            </div>
            <Button
              size="sm"
              href="/autosave-form"
              className="fs-6 w-100"
            >Continue Application
            </Button>
          </div>
        }
      </div>

      {isAgent &&
        <div>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group className='mb-2' controlId='formForceNumber'>
              <Form.Label className='Label'>Representative Code *</Form.Label>
              <InputGroup hasValidation>
                <Form.Control className='Control' type='text' required value={agentCode} onChange={(e) => setAgentCode(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                  Please Enter Agent Code.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="fullNames">Representative Name *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="FirstName"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="First Name"
                  aria-describedby="inputGroupPrepend"
                  value={representativeName}
                  onChange={(e) => setRepresentativeName(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  FirstName is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a FirstName.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="surname">Representative Surname *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="Surname"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="Surname"
                  aria-describedby="inputGroupPrepend"
                  value={representativeSurname}
                  onChange={(e) => setRepresentativeSurname(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  Surname is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Surname.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Footer>
              <ul className="btn-list">
                <li className="back-li">
                  <BtnCompo
                    onClick={() => setIsAgent(false)}
                    buttonIcon={"/images/icon-back.png"}
                    buttonClass={"btn-back"}
                    buttonIconHeight={22}
                    buttonIconWidth={27}
                  />
                </li>
                <li>
                  <BtnCompo
                    buttonAction={"/decline"}
                    buttonText={"DECLINE"}
                    buttonOutline={true}
                  />
                </li>
                <li>
                  <Button variant="primary" type="submit">
                    NEXT
                  </Button>
                </li>
              </ul>
            </Footer>
          </Form>
        </div>}
    </Container>
  );
};

export default Home;
