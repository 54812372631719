import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React, { useEffect, useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const CheckInfo = () => {
  const [validated, setValidated] = useState(false);
  const [sourceIncome, setSourceIncome] = useState('');
  const [sourceIncomeDetails, setSourceIncomeDetails] = useState('');
  const [dpep, setDpep] = useState('');
  const [dpepDetails, setDpepDetails] = useState('');
  const [fpep, setFppo] = useState('');
  const [fpepDetails, setFppoDetails] = useState('');
  const [pip, setPIP] = useState('');
  const [pipDetails, setPIPDetails] = useState('');
  const [closeDpepFppo, setCloseDpepFppo] = useState('');
  const [closeDpepFppoDetails, setCloseDpepFppoDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('completedApp'))
      navigate("/extended-family-selection")

    if (localStorage.getItem('checkInfoData')) {
      let checkInfo = JSON.parse(localStorage.getItem('checkInfoData'));
      setSourceIncome(checkInfo.sourceIncome);
      setSourceIncomeDetails(checkInfo.sourceIncomeDetails);
      setDpep(checkInfo.dpep);
      setDpepDetails(checkInfo.dpepDetails);
      setFppo(checkInfo.fpep);
      setFppoDetails(checkInfo.fpepDetails);
      setPIP(checkInfo.pip);
      setPIPDetails(checkInfo.pipDetails);
      setCloseDpepFppo(checkInfo.closeDpepFppo);
      setCloseDpepFppoDetails(checkInfo.closeDpepFppoDetails);
    }
  }, [navigate])

  useEffect(() => {
    if (approved) {
      setIsLoading(true);
      let representativeData; let profile; let selfCoverDatas; let extendedFamily; let totalCost; let employeeDetail; let MainId; let address;
      let beneficiary; let bankingDetailsDO; let checkInfoData; let addInfo; let indexPage;
      if (localStorage.getItem("selfCoverData")) selfCoverDatas = JSON.parse(localStorage.getItem("selfCoverData"));
      if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
      if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
      if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
      if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));
      if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      if (localStorage.getItem("MainId")) MainId = JSON.parse(localStorage.getItem("MainId"));
      if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));
      if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      if (localStorage.getItem("bankingDetailsDO")) bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));
      if (localStorage.getItem("checkInfoData")) checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));
      if (localStorage.getItem("addInfo")) addInfo = JSON.parse(localStorage.getItem("addInfo"));

      let payload = {
        page: 'check-info',
        sourceData: "funeralApp",
        representativeData,
        profile,
        selfCoverDatas,
        extendedFamily,
        indexPage,
        totalCost,
        address,
        employeeDetail,
        beneficiary,
        bankingDetailsDO,
        checkInfoData,
        MainId,
        addInfo
      }

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          setIsLoading(false);
          navigate("/add-info");
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  }, [approved, navigate])

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (sourceIncome && dpep && fpep && closeDpepFppo) {
      let checkInfo = {
        sourceIncome: sourceIncome,
        sourceIncomeDetails: sourceIncomeDetails,
        dpep: dpep,
        dpepDetails: dpepDetails,
        fpep: fpep,
        fpepDetails: fpepDetails,
        pip: pip,
        pipDetails: pipDetails,
        closeDpepFppo: closeDpepFppo,
        closeDpepFppoDetails: closeDpepFppoDetails
      }
      localStorage.setItem('checkInfoData', JSON.stringify(checkInfo));
    }

    setValidated(true);
  };

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">Client Due Diligence</h1>

        <div className="text-sm">
          <p>DPEP refers to a <b>Domestic Politically Exposed Person,</b> is an individual who holds, including in an acting position for a period exceeding 6 months, has held a prominent public function in the Republic including that of-</p>
          <ul>
            <li>President or Deputy President</li>
            <li>Government minister or Deputy minister</li>
            <li>Member of a royal family or senior traditional
              leader</li>
            <li>Premier of a Province</li>
            <li>Executive Council member of a Province</li>
            <li>Leader of a political party</li>
            <li>Executive mayor of a municipality</li>
            <li>Municipal manager or CFO of a municipality</li>
            <li>Chairperson of the controlling body, CEO, CFO, CIO of a municipal entity</li>
            <li>Head, accounting officer of CFO of a national /
              provincial department or government
              component</li>
            <li>Chairperson of the controlling body, CEO, CFO, CIO or a natural person who is the accounting authority of a public entity</li>
            <li>Constitutional court judge or any other judge</li>
            <li>Ambassador, or high commissioner or other senior representative of a foreign government based in the Republic; or</li>
            <li>holds, including in an acting position for a period exceeding six month, or has held the position of head, or other executive directly accountable to that head, of an international organisation.</li>
            <li>Officer of the South African National Defence Force above the rank of major-general</li>
          </ul>
          <p>FPEP refers to a <b> Foreign Politically Exposed Person,</b> is an individual who holds, or has held, in a foreign country, a prominent public function including that of:</p>
          <ul>
            <li>Head of State / Head of a Country or
              Government</li>
            <li>Member of a foreign Royal family</li>
            <li>Government Minister or equivalent senior
              politician or leader of a political party</li>
            <li>Senior executive of a state-owned corporation</li>
            <li>High ranking member of the military (General;
              Lieutenant General; Admiral or Vice Admiral)</li>
            <li>Senior judicial official</li>
          </ul>
          <p>PIP refers to a <b>Prominent Influential Person </b>is an individual who holds, or has held at any time in the preceding 12 months, in a company that provides goods or services to an organ of state, the position of:</p>
          <ul>
            <li>Chairperson of the board of directors:</li>
            <li>Chairperson of the audit committee;</li>
            <li>Executive officer or Chief financial officer,</li>
          </ul>
          <p><b>Family members</b> and known <b>close associates,</b> of a foreign or domestic politically exposed person or prominent influential person, as the case may be, including immediate family members;</p>
          <ul>
            <li>Spouse, civil partner or life partner,</li>
            <li>Parents,</li>
            <li>Previous spouse, civil partner or life partner, if applicable;</li>
            <li>Sibling and step sibling and their spouse, civil partner or life partner. </li>
            <li>Children and step children and their spouse, civil partner or life partner;</li>
          </ul>
        </div>

        <Form noValidate validated={validated}>
          <Form.Group className='mb-2' controlId='sourceIncome'>
            <Form.Label className='Label'>* What is the source of Funds?</Form.Label>
            <Form.Select required value={sourceIncome} onChange={(e) => setSourceIncome(e.target.value)}>
              <option value='' disabled>Choose Source Income</option>
              <option value="Salary">Salary</option>
              <option value="Pension">Pension</option>
              <option value="Business Income">Business Income</option>
              <option value="Dividend">Dividend</option>
              <option value="Savings">Savings</option>
              <option value="Other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="valid">
              Source Income is valid.
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please choose a Source Income.
            </Form.Control.Feedback>
            {sourceIncome === 'Other' && <>
              <Form.Control value={sourceIncomeDetails} required className='Control' type='text' placeholder='Please provide details:' onChange={(e) => setSourceIncomeDetails(e.target.value)} style={{ marginTop: '20px' }} />

              <Form.Control.Feedback type="invalid">
                Please choose a other details.
              </Form.Control.Feedback>
            </>}
          </Form.Group>

          <Form.Group className='mb-2' controlId='dpep'>
            <Form.Label className='Label'>* Are you a Domestic Politically Exposed Person (DPEP)?</Form.Label>
            <Form.Select required value={dpep} onChange={(e) => setDpep(e.target.value)} >
              <option value=''>Choose Yes or No</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Select>
            <Form.Control.Feedback type="valid">
              DPEP is valid.
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              You have not indicated if you are a DPEP
            </Form.Control.Feedback>
            {dpep === 'Yes' && <>
              <Form.Control value={dpepDetails} className='Control' required type='text' placeholder='Please provide details:' onChange={(e) => setDpepDetails(e.target.value)} style={{ marginTop: '20px' }} />

              <Form.Control.Feedback type="invalid">
                Please choose a DPEP details.
              </Form.Control.Feedback>
            </>}
          </Form.Group>

          <Form.Group className='mb-2' controlId='fpep'>
            <Form.Label className='Label' style={{ fontSize: '12px' }}>* Are you a Foreign Politically Exposed Person (FPEP)?</Form.Label>
            <Form.Select required value={fpep} onChange={(e) => setFppo(e.target.value)} >
              <option value=''>Choose Yes or No</option>
              <option>Yes</option>
              <option>No</option>
            </Form.Select>
            <Form.Control.Feedback type="valid">
              FPEP is valid.
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              You have not indicated if you are a FPEP
            </Form.Control.Feedback>
            {fpep === 'Yes' && <>
              <Form.Control className='Control' type='text' required value={fpepDetails} placeholder='Please provide details:' onChange={(e) => setFppoDetails(e.target.value)} style={{ marginTop: '20px' }} />

              <Form.Control.Feedback type="invalid">
                Please choose a FPEP details.
              </Form.Control.Feedback>
            </>}
          </Form.Group>

          <Form.Group className='mb-2' controlId='fpep'>
            <Form.Label className='Label' style={{ fontSize: '12px' }}>* Are you a Prominent Influential Person(PIP)?</Form.Label>
            <Form.Select required value={pip} onChange={(e) => setPIP(e.target.value)} >
              <option value=''>Choose Yes or No</option>
              <option>Yes</option>
              <option>No</option>
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              You have not indicated if you are a PIP
            </Form.Control.Feedback>
            {pip === 'Yes' && <>
              <Form.Control className='Control' required type='text' value={pipDetails} placeholder='Please provide details:' onChange={(e) => setPIPDetails(e.target.value)} style={{ marginTop: '20px' }} />

              <Form.Control.Feedback type="invalid">
                Please choose a PIP details.
              </Form.Control.Feedback>
            </>}
          </Form.Group>


          <Form.Group className='mb-2' controlId='closeDpepFppo'>
            <Form.Label className='Label' style={{ fontSize: '12px' }}>* Is a close family member or any associates of yours a DPEP or FPEP or PIP?</Form.Label>
            <Form.Select required value={closeDpepFppo} onChange={(e) => setCloseDpepFppo(e.target.value)} >
              <option value=''>Choose Yes or No</option>
              <option>Yes</option>
              <option>No</option>
            </Form.Select>
            <Form.Control.Feedback type="valid">
              DPEP or fpep is valid.
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              You have not indicated if you are a close family member or have any association with a DPEP or FPEP or PIP
            </Form.Control.Feedback>
            {closeDpepFppo === 'Yes' && <>
              <Form.Control className='Control' required type='text' value={closeDpepFppoDetails} placeholder='Please provide details:' onChange={(e) => setCloseDpepFppoDetails(e.target.value)} style={{ marginTop: '20px' }} />

              <Form.Control.Feedback type="invalid">
                Please choose a DPEP or FPEP or PIP details.
              </Form.Control.Feedback>
            </>}
          </Form.Group>

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/payment-method"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button variant="primary" onClick={(e) => { handleSubmit(e); setApproved(true); }} disabled={!sourceIncome || !dpep || !fpep || !closeDpepFppo}>
                  NEXT
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default CheckInfo;
