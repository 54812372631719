import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import Slider from "components/slider";
import React, { useState, useEffect } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const MyCover = () => {
  const [disabledFuneral, setDisabledFuneral] = useState(true);
  const [disabledAccidental, setDisabledAccidental] = useState(true);
  const [disabledIncome, setDisabledIncome] = useState(true);
  const [cover, setCover] = useState([]);
  const [funeralAmount, setFuneralAmount] = useState(0);
  const [funeralCost, setFuneralCost] = useState(0);
  const [accidentalAmount, setAccidentalAmount] = useState(0);
  const [accidentalCost, setAccidentalCost] = useState(0);
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [incomeCost, setIncomeCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [mainAge, setMainAge] = useState(0);
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);
  const [availableCost, setAvailableCost] = useState(100000);
  const [error, setError] = useState(false);
  const [illnessBenefits, setIllnessBenefits] = useState(0);
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState(false);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const selectCover = queryParams.get('selectedCover') || null;

  useEffect(() => {
    if (localStorage.getItem('completedApp'))
      navigate("/extended-family-selection")

    if (localStorage.getItem('profile')) {
      const age = JSON.parse(localStorage.getItem('profile')).mainAge;
      if (age >= 65 || age <= 17) setDisabledCheckbox(true);
    } else { navigate("/profile"); }

    axios
      .get("./selfCover.json")
      .then((res) => {
        if (res.data) setCover(res.data);
      })
      .catch((err) => console.log(err));

    if (localStorage.getItem('profile')) setMainAge(JSON.parse(localStorage.getItem('profile')).mainAge);

    if (localStorage.getItem('selfCoverData')) {
      const selfCoverData = JSON.parse(localStorage.getItem("selfCoverData"));
      setFuneralAmount(selfCoverData.funeralAmount);
      setFuneralCost(selfCoverData.funeralCost);
      setAccidentalAmount(selfCoverData.accidentalAmount);
      setAccidentalCost(selfCoverData.accidentalCost);
      setIncomeAmount(selfCoverData.incomeAmount);
      setIncomeCost(selfCoverData.incomeCost);
      setIllnessBenefits(selfCoverData.illnessBenefits);
    } else setError(true);
  }, [navigate]);

  const changeSelection = (e, value) => {
    if (value === "funeral") {
      if (e === true) {
        setDisabledFuneral(false);
        setType('funeral');
        handleShow();
      }
      else {
        setDisabledFuneral(true);
        setFuneralCost(0);
        setFuneralAmount(0);
      }
    }
    if (value === "accidental") {
      if (e === true) {
        setDisabledAccidental(false);
        setType('accidental');
        handleShow();
      }
      else {
        setDisabledAccidental(true);
        setAccidentalCost(0);
        setAccidentalAmount(0);
      }
    }
    if (value === "income") {
      if (e === true) {
        setDisabledIncome(false);
        setType('income');
        handleShow();
      }
      else {
        setDisabledIncome(true);
        setIncomeCost(0);
        setIncomeAmount(0);
      }
    }
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    let cost = Number(funeralAmount) + Number(accidentalAmount) + Number(incomeAmount * 3);
    setAvailableCost(100000 - Number(cost));
  }, [accidentalAmount, funeralAmount, incomeAmount]);

  useEffect(() => {
    let total = funeralCost + accidentalCost + incomeCost;
    setIllnessBenefits(funeralAmount * 20 / 100);
    setTotalCost(parseFloat(total).toFixed(2));
  }, [funeralCost, accidentalCost, incomeCost, funeralAmount]);

  const changeValue = (coverName, e) => {
    setError(false);
    localStorage.removeItem('totalCost');
    cover.forEach((item) => {
      if (item.coverName === coverName) {
        item.benefits.forEach((value) => {
          if (value.amount === e) {
            value.allCost.forEach(ageCost => {
              if (mainAge <= ageCost.agemax && mainAge >= ageCost.agemin) {
                if (coverName === "funeral") {
                  setFuneralCost(ageCost.cost);
                  setFuneralAmount(e);
                  if (accidentalAmount >= e) {
                    setAccidentalCost(0);
                    setAccidentalAmount(0);
                  }
                  if (accidentalAmount >= e) {
                    setIncomeCost(0);
                    setIncomeAmount(0);
                  }
                }
                if (coverName === "accidental" && funeralAmount >= e) {
                  setAccidentalCost(ageCost.cost);
                  setAccidentalAmount(e);
                }
                if (coverName === "income") {
                  setIncomeCost(ageCost.cost);
                  setIncomeAmount(e);
                }
              }
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (approved) {
      let representativeData; let profile; let extendedFamily; let employeeDetail; let MainId; let address;
      let beneficiary; let bankingDetailsDO; let checkInfoData; let addInfo; let indexPage;
      if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
      if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
      if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
      if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      if (localStorage.getItem("MainId")) MainId = JSON.parse(localStorage.getItem("MainId"));
      if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));
      if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      if (localStorage.getItem("bankingDetailsDO")) bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));
      if (localStorage.getItem("checkInfoData")) checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));
      if (localStorage.getItem("addInfo")) addInfo = JSON.parse(localStorage.getItem("addInfo"));
      const selfCoverDatas = {
        funeralAmount: funeralAmount,
        funeralCost: funeralCost,
        accidentalAmount: accidentalAmount,
        accidentalCost: accidentalCost,
        incomeAmount: incomeAmount,
        incomeCost: incomeCost,
        totalCost: totalCost,
        illnessBenefits: illnessBenefits
      }

      let payload = {
        page: 'my-cover',
        sourceData: "funeralApp",
        representativeData,
        profile,
        selfCoverDatas,
        extendedFamily,
        indexPage,
        address,
        employeeDetail,
        beneficiary,
        bankingDetailsDO,
        checkInfoData,
        addInfo,
        MainId
      }

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          setIsLoading(false);
          localStorage.setItem('selfCoverData', JSON.stringify(selfCoverDatas));
          selectCover === 'family' ? navigate('/extended-family-selection') : navigate('/quote-summary');
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }

  }, [accidentalAmount, accidentalCost, approved, funeralAmount, funeralCost, illnessBenefits, incomeAmount, incomeCost, navigate, selectCover, totalCost])

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">
          COVER SELECTION
          <br />
          FOR YOU
        </h1>
        <div className="text-center text-sm">
          <p>PLEASE SELECT THE AMOUNT OF COVER REQUIRED.</p>
          <p>*NOTE - MAXIMUM COVER ACROSS PRODUCTS IS LIMITED TO R100 000</p>
          {disabledCheckbox ? <p style={{ color: "red", fontSize: "12px", fontStyle: "bold" }}>No Cover benefits are available for this Age</p> : ''}
          {error ? <p style={{ color: "red", fontSize: "12px", fontStyle: "bold" }}>Please Select Funeral Cover</p> : ''}
        </div>
        <div className="text-sm">
          <p className="mb-2">
            <strong>SELECT</strong>
          </p>
        </div>
        <div className="cover-list">
          <div className="cover-list-item">
            <div className="cover-list-input">
              <input
                disabled={disabledCheckbox}
                className="form-check-input"
                type="checkbox"
                value="Funeral Cover"
                id="cover1"
                onChange={(e) => changeSelection(e.target.checked, "funeral")}
              />
            </div>
            <div className="cover-list-slider-container">
              <div className="cover-list-title">Funeral Cover</div>
              <div className="cover-list-slider">
                <Slider
                  min={0}
                  max={100000}
                  step={5000}
                  title={"Funeral Cover"}
                  value={funeralAmount}
                  onChange={(changeEvent) =>
                    changeValue("funeral", changeEvent.target.value)
                  }
                  disabled={disabledFuneral}
                />
              </div>
            </div>
            <div className="cover-list-cost">
              <div className="cover-list-title">COST</div>
              <div className="cover-list-price">R{funeralCost.toFixed(2)}</div>
            </div>
          </div>
          <div className="cover-list-item">
            <div className="cover-list-input">
              <input
                disabled={disabledCheckbox}
                className="form-check-input"
                type="checkbox"
                value="Accidental Cover"
                id="cover2"
                onChange={(e) =>
                  changeSelection(e.target.checked, "accidental")
                }
              />
            </div>
            <div className="cover-list-slider-container">
              <div className="cover-list-title">Accidental Cover</div>
              <div className="cover-list-slider">
                <Slider
                  min={0}
                  max={50000}
                  step={5000}
                  title={"Accidental Cover"}
                  value={accidentalAmount}
                  onChange={(changeEvent) =>
                    changeValue("accidental", changeEvent.target.value)
                  }
                  disabled={disabledAccidental}
                />
              </div>
            </div>
            <div className="cover-list-cost">
              <div className="cover-list-price">R{accidentalCost.toFixed(2)}</div>
            </div>
          </div>
          <div className="cover-list-item">
            <div className="cover-list-input">
              <input
                disabled={disabledCheckbox}
                className="form-check-input"
                type="checkbox"
                value="Income Cover"
                id="cover3"
                onChange={(e) => changeSelection(e.target.checked, "income")}
              />
            </div>
            <div className="cover-list-slider-container ms-2">
              <div className="cover-list-title">Income Booster</div>
              <Form.Select
                disabled={disabledIncome}
                aria-describedby="inputGroupPrepend"
                aria-label="IncomeBooster"
                value={incomeAmount}
                onChange={(e) => changeValue("income", e.target.value)}
              >
                <option value="">Select Amount</option>
                <option value="5000">5000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </Form.Select>
            </div>
            {/* <div className="cover-list-slider-container">
              <div className="cover-list-title">Income Booster</div>
              <div className="cover-list-slider">
                <Slider
                  min={0}
                  max={10000}
                  step={1000}
                  title={"Income Booster"}
                  value={incomeAmount}
                  onChange={(changeEvent) =>
                    changeValue("income", changeEvent.target.value)
                  }
                  disabled={disabledIncome}
                />
              </div>
            </div> */}
            <div className="cover-list-cost">
              <div className="cover-list-title"></div>
              <div className="cover-list-price">R{incomeCost.toFixed(2)}</div>
            </div>
          </div>
          <div className="text-sm">
            <p>* Total income booster {incomeAmount * 3} payable {incomeAmount} for three months.</p>
          </div>
          <div className="cover-list-item">
            <div className="cover-list-slider-container">
              <div className="cover-list-title-total">Total Premium Cost</div>
            </div>
            <div className="cover-list-cost">
              <div className="cover-list-price-total">R{totalCost}</div>
            </div>
          </div>
        </div>
        <div className="text-center text-sm my-2">
          <p>VALUE ADDED BENEFIT (ONLY)</p>
          <p>
            TERMINAL ILLNESS BENEFITS 20% OF FUNERAL COVER <strong>R{illnessBenefits}</strong>
          </p>
        </div>
        {availableCost === 0 && <p className="text-center" style={{ color: "red", fontSize: "12px", fontStyle: "bold" }}>
          The Maximum Total Cover 100 000 has been selected
        </p>}
        {availableCost < 0 && <p className="text-center" style={{ color: "red", fontSize: "12px", fontStyle: "bold" }}>
          The Maximum Total Cover 100 000 has been exceed. Please Reduce Cover Amount
        </p>}
        <div className="text-center">
          <h3 className="text-primary title-md">TOTAL COVER STILL AVAILABLE</h3>
          <h2 className="text-success title-lg">R{availableCost}</h2>
        </div>
        <Footer>
          <ul className="btn-list">
            <li className="back-li">
              <BtnCompo
                buttonAction={"/select-cover"}
                buttonIcon={"/images/icon-back.png"}
                buttonClass={"btn-back"}
                buttonIconHeight={22}
                buttonIconWidth={27}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={"/rather-call-me"}
                buttonText={"RATHER CALL ME"}
                buttonOutline={true}
                buttonClass={"btn-rather"}
              />
            </li>
            <li>
              <Button
                disabled={funeralAmount <= 0} onClick={(e) => { setApproved(true); setIsLoading(true); }}
              >NEXT</Button>
            </li>
          </ul>
        </Footer>
      </div>

      <Modal show={show} onHide={handleClose} centered backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>NOTE:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {type === 'income' && <p> A benefit that will provide three regular monthly payments following the death of the Principal Member. This benefit may be selected as an additional benefit.</p>}
            {type === 'accidental' && <p> A sudden, uncertain and unexpected event which is caused solely and directly by Violent, External, Physical and Visible means and independently of any other causes resulting in the death of the Insured withing 30 days of the accidental injury. </p>}
            {type === 'funeral' && <p>An accelerated lump sum benefit of 20% of the selected funeral benefit is paid if the Principal member is diagnosed with a terminal illness and has 12 months to live. A similar waiting period as the death benefit will apply.</p>}
          </div>
        </Modal.Body>
      </Modal>
    </Container >
  );
};

export default MyCover;
