import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import ImgCompo from "components/img-compo";
import Footer from "components/footer";
import OtpInput from 'react-otp-input';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import XMLParser from 'react-xml-parser';

const AutoSaveForm = () => {
    const [IDNumberError, setIDNumberError] = useState("");
    const [validated, setValidated] = useState(false);
    const [IDNumber, setIDNumber] = useState("");
    const [cellNumber, setCellNumber] = useState("");
    const [cellNumberError, setCellNumberError] = useState("");
    const [submitData, setSubmitData] = useState(false);
    const [receviedOTP, setReceviedOTP] = useState();
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState();
    const [timerStart, setTimerStart] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [disabledResend, setDisabledResend] = useState(false);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setCellNumber(e.target.value);
        }
    };

    const checkCellNumber = (number) => {
        if (number.charAt(0) === "0") {
            if (number.length > 10) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else if (number.length < 10) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else {
                setCellNumberError("");
                return true;
            }
        } else if (number.charAt(0) === "2") {
            if (number.length > 11) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else if (number.length < 11) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else {
                setCellNumberError("");
                return true;
            }
        } else {
            setCellNumberError("Please give a valid cell number");
        }
    };

    const checkIDNumber = (number) => {
        if (number.length < 13) {
            setIDNumberError("Please give a valid ID Number");
            return false;
        } else {
            setIDNumberError("");
            return true;
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (IDNumber && cellNumber && !cellNumberError) {
            setIsLoading(true);
            GetOTP();
        }
        setValidated(true);
    }

    const GetOTP = () => {
        const xml = `<?xml version="1.0" encoding="utf-8"?>
            <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <ShieldOTPGet xmlns="http://tempuri.org/">
                        <mobile>${cellNumber}</mobile>
                        <id>${IDNumber}</id>
                    </ShieldOTPGet>
                </soap:Body>
            </soap:Envelope>`

        var config = {
            method: "post",
            url: "https://shieldapp.msgportal.online/msgportal.asmx?op=ShieldOTPGet",
            data: xml,
            headers: {
                'Content-Type': 'text/xml'
            }
        };

        axios(config)
            .then(function (response) {
                var xml = new XMLParser().parseFromString(response.data);
                const data = xml.children[0].children[0].children[0].value;
                setIsLoading(false);
                if (data !== "nodata") {
                    setError(false);
                    setSubmitData(true);
                    setReceviedOTP(data);
                } else setError(true);
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const resendOTP = () => {
        GetOTP();
    }

    useEffect(() => {
        localStorage.clear();
        if (timerStart) {
            if (timeLeft === 0) {
                setDisabledResend(false);
                setTimeLeft(null)
            }

            if (!timeLeft) return;

            const intervalId = setInterval(() => {
                setDisabledResend(true);
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }

    }, [timeLeft, timerStart])

    const validateOTP = () => {
        setError(false);
        if (otp === receviedOTP) {
            setIsLoading(true);
            const xml = `<?xml version="1.0" encoding="utf-8"?>
            <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <ShieldPLGet xmlns="http://tempuri.org/">
                        <token>lkhbnoyti76tbri7tfjyfcubyt75rtbfity</token>
                        <id>${IDNumber}</id>
                    </ShieldPLGet>
                </soap:Body>
            </soap:Envelope>`

            var config = {
                method: 'post',
                url: 'https://shieldapp.msgportal.online/msgportal.asmx?op=ShieldPLGet',
                data: xml,
                headers: {
                    'Content-Type': 'text/xml'
                }
            };

            axios(config)
                .then(response => {
                    setIsLoading(false);
                    var xml = new XMLParser().parseFromString(response.data);
                    let data = JSON.parse(xml.getElementsByTagName('ShieldPLGetResult')[0].value);
                    if (data.length > 0) {
                        data = { ...data[0] }
                        console.log(data)
                        if (data.page) {
                            if (data.representativeData) localStorage.setItem("representativeData", JSON.stringify(data.representativeData));
                            if (data.profile) localStorage.setItem("profile", JSON.stringify(data.profile));
                            if (data.selfCoverData) localStorage.setItem('selfCoverData', JSON.stringify(data.selfCoverData));
                            if (data.extendedFamilyArray) localStorage.setItem("extendedFamily", JSON.stringify(data.extendedFamilyArray));
                            if (data.indexPage) localStorage.setItem("indexPage", JSON.stringify(data.indexPage));
                            if (data.totalCost) localStorage.setItem('totalCost', JSON.stringify(data.totalCost));
                            if (data.employeeDetail) localStorage.setItem("employeeDetails", JSON.stringify(data.employeeDetail));
                            if (data.MainId) localStorage.setItem("MainId", JSON.stringify(data.MainId));
                            if (data.address) localStorage.setItem("profileAddress", JSON.stringify(data.address));
                            if (data.beneficiary) localStorage.setItem("beneficiary", JSON.stringify(data.beneficiary));
                            if (data.bankingDetailsDO) localStorage.setItem("bankingDetailsDO", JSON.stringify(data.bankingDetailsDO));
                            if (data.checkInfoData) localStorage.setItem('checkInfoData', JSON.stringify(data.checkInfoData));
                            if (data.addInfo) localStorage.setItem('addInfo', JSON.stringify(data.addInfo));
                            navigate(`/${data.page}`);
                        } else {
                            setShow(true);
                            const representativeData = data.representativeData;
                            const profile = {
                                rankTitle: data.rankTitle,
                                fullNames: data.fullNames,
                                surname: data.surname,
                                mainDOB: data.mainDOB,
                                mainAge: data.mainAge,
                                cellNumber: data.cellNumber,
                                alternativeNo: data.alternativeNo,
                                emailAddress: data.emailAddress,
                                IDNumber: data.IDNumber,
                                contactMethod: data.contactMethod
                            };
                            const selfCoverData = {
                                funeralAmount: data.funeralAmount,
                                funeralCost: data.funeralCost,
                                accidentalAmount: data.accidentalAmount,
                                accidentalCost: data.accidentalCost,
                                incomeAmount: data.incomeAmount,
                                incomeCost: data.incomeCost,
                                totalCost: data.totalCost,
                                illnessBenefits: data.illnessBenefits
                            };
                            const extendedFamily = data.extendedFamily;
                            const indexPage = data.extendedFamily ? data.extendedFamily.length : data.extendedFamilyArray.length;
                            const signature = data.signature;
                            const totalCost = {
                                totalIllness: data.totalIllness,
                                totalCoverCost: data.totalCost,
                                finalTotal: data.finalTotal,
                                premiumWaiver: data.premiumWaiver,
                                premiumWaiverAmount: data.premiumWaiverAmount,
                                premiumHoliday: data.premiumHoliday,
                                premiumHolidayAmount: data.premiumHolidayAmount
                            };
                            const employeeDetail = {
                                employmentSector: data.employmentSector,
                                employerName: data.employerName,
                                businessTel: data.businessTel,
                                employeeNo: data.employeeNo,
                                jobTitle: data.jobTitle,
                                departmentUnit: data.departmentUnit
                            };

                            const profileAddress = {
                                streetName: data.streetName,
                                suburb: data.suburb,
                                town: data.town,
                                postalCode: data.postalCode,
                            };
                            const MainId = { mainID: data.mainID };
                            const beneficiary = {
                                beneficiaryName: data.beneficiaryName,
                                beneficiarySurname: data.beneficiarySurname,
                                beneficiaryAge: data.beneficiaryAge,
                                beneficiaryDOB: data.beneficiaryDOB,
                                beneficiaryRelationship: data.beneficiaryRelationship,
                                beneficiaryGender: data.beneficiaryGender,
                                beneficiaryCellNumber: data.beneficiaryCellNumber,
                                beneficiaryIDNo: data.beneficiaryIDNo,
                                beneficiaryUploadID: data.beneficiaryUploadID,
                                beneficiarySecondaryName: data.beneficiarySecondaryName,
                                beneficiarySecondarySurname: data.beneficiarySecondarySurname,
                                beneficiarySecondaryAge: data.beneficiarySecondaryAge,
                                beneficiarySecondaryDOB: data.beneficiarySecondaryDOB,
                                beneficiarySecondaryRelationship: data.beneficiarySecondaryRelationship,
                                beneficiarySecondaryGender: data.beneficiarySecondaryGender,
                                beneficiarySecondaryCellNumber: data.beneficiarySecondaryCellNumber,
                                beneficiarySecondaryIDNo: data.beneficiarySecondaryIDNo,
                                beneficiarySecondaryUploadID: data.secondaryData
                            };
                            const bankingDetailsDO = data.bankingDetailsDO;
                            let checkInfo = {
                                sourceIncome: data.sourceIncome,
                                sourceIncomeDetails: data.sourceIncomeDetails,
                                dpep: data.dpep,
                                dpepDetails: data.dpepDetails,
                                fpep: data.fpep,
                                fpepDetails: data.fpepDetails,
                                pip: data.pip,
                                pipDetails: data.pipDetails,
                                closeDpepFppo: data.closeDpepFppo,
                                closeDpepFppoDetails: data.closeDpepFppoDetails
                            }
                            const addInfo = data.addInfo;

                            localStorage.setItem("representativeData", JSON.stringify(representativeData));
                            localStorage.setItem("profile", JSON.stringify(profile));
                            localStorage.setItem('selfCoverData', JSON.stringify(selfCoverData));
                            localStorage.setItem("extendedFamily", JSON.stringify(extendedFamily));
                            localStorage.setItem("signature", JSON.stringify({ signature: signature }));
                            localStorage.setItem("indexPage", JSON.stringify(indexPage));
                            localStorage.setItem('totalCost', JSON.stringify(totalCost));
                            localStorage.setItem("employeeDetails", JSON.stringify(employeeDetail));
                            localStorage.setItem("MainId", JSON.stringify(MainId));
                            localStorage.setItem("profileAddress", JSON.stringify(profileAddress));
                            localStorage.setItem("beneficiary", JSON.stringify(beneficiary));
                            localStorage.setItem("bankingDetailsDO", JSON.stringify(bankingDetailsDO));
                            localStorage.setItem('checkInfoData', JSON.stringify(checkInfo));
                            localStorage.setItem('addInfo', JSON.stringify(addInfo));
                            localStorage.setItem('completedApp', true);
                        }
                    }
                });
        } else {
            setError(true);
        }
    }

    const storeData = () => {
        navigate(`/extended-family-selection`);
    }

    const handleClose = () => setShow(false);

    return (
        <Container className="h-100">
            {isLoading && <div className="loaderOverlay">
                <Spinner animation="border" role="status" variant="light" />

                <div>Submitting Application, please wait...</div>
            </div>}
            <div className="main-content">
                <h1 className="page-title mb-5">
                    ADD YOUR DETAILS
                </h1>

                {!submitData ? <Form noValidate validated={validated}>
                    <Form.Group as={Col} xs="12">
                        <Form.Label htmlFor="IDNumber">ID Number *</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                                <ImgCompo
                                    src="/images/icon-user.png"
                                    alt="Surname"
                                    width={16}
                                    height={16}
                                />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="ID Number"
                                aria-describedby="inputGroupPrepend"
                                isInvalid={IDNumberError}
                                maxLength={13}
                                value={IDNumber}
                                onChange={(e) => { setIDNumber(e.target.value); checkIDNumber(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {IDNumberError
                                    ? IDNumberError
                                    : "Please Enter a ID Number."}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs="12">
                        <Form.Label htmlFor="cellNumber">Cell Phone Number *</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                                <ImgCompo
                                    src="/images/icon-phone.png"
                                    alt="CellNumber"
                                    width={16}
                                    height={16}
                                />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Cell Phone Number"
                                aria-describedby="inputGroupPrepend"
                                value={cellNumber}
                                isInvalid={cellNumberError}
                                maxLength={11}
                                pattern="[0-9]*"
                                onChange={(e) => { handleChange(e); checkCellNumber(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {cellNumberError
                                    ? cellNumberError
                                    : "Please Enter a Cell Phone Number."}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    {error && <div style={{ color: "red", marginTop: "20px" }}>Please enter a valid IDNumber and Mobile Number</div>}
                    <Footer>
                        <ul className="btn-list">
                            <li> <Button className="text-center w-auto m-auto" variant="primary" onClick={(e) => { handleSubmit(e); }}>
                                Submit
                            </Button>
                            </li>
                        </ul>
                    </Footer>
                </Form> :
                    <>

                        <div className="d-flex flex-column align-items-center justify-content-center h-100 otp-input">
                            <h6 style={{ color: '#0b58a6' }}>Please enter OTP *</h6>
                            <OtpInput
                                className="form-control"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                            />
                            <Button disabled={disabledResend} className="text-center mt-3 p-2 fs-6" variant="primary" onClick={(e) => { resendOTP(); setTimerStart(true); setTimeLeft(30) }}>
                                Resend OTP
                            </Button>
                            {error && <div style={{ color: "red", marginTop: "20px" }}>Invalid OTP has been Entered</div>}
                            <Footer>
                                <ul className="btn-list">
                                    <li> <Button className="text-center w-auto m-auto" variant="primary" onClick={(e) => { validateOTP() }}>
                                        Submit
                                    </Button>
                                    </li>
                                </ul>
                            </Footer>
                        </div>
                    </>
                }
            </div>

            <button
                data-confirm="Are you sure?">
                Confirm Button
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to add more Extended Members ?
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" className="p-2 fs-6" onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={() => storeData()} size="sm" className="p-2 fs-6" variant="primary">Yes</Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default AutoSaveForm;