import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const AddInfo = () => {

  const [affordableFunerral, setAffordableFunerral] = useState('');
  const [funeralMyself, setFuneralMyself] = useState('');
  const [funeralMyFamily, setFuneralMyFamily] = useState('');
  const [funeralExtendedFamily, setFuneralExtendedFamily] = useState('');
  const [additionalBenifits, setAdditionalBenifits] = useState('');
  const [sendInfo, setSendInfo] = useState('yes');
  const [other, setOther] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('completedApp'))
      navigate("/extended-family-selection")

    if (localStorage.getItem('addInfo')) {
      let reasons = JSON.parse(localStorage.getItem('addInfo'))
      if (reasons.affordableFunerral) setAffordableFunerral(reasons.affordableFunerral);
      if (reasons.funeralMyself) setFuneralMyself(reasons.funeralMyself);
      if (reasons.funeralMyFamily) setFuneralMyFamily(reasons.funeralMyFamily);
      if (reasons.funeralExtendedFamily) setFuneralExtendedFamily(reasons.funeralExtendedFamily);
      if (reasons.additionalBenifits) setAdditionalBenifits(reasons.additionalBenifits);
      if (reasons.other) setOther(reasons.other);
      if (reasons.sendInfo) setSendInfo(reasons.sendInfo);
    }
  }, [navigate])

  useEffect(() => {
    if (approved) {
      setIsLoading(true);
      let representativeData; let profile; let selfCoverDatas; let extendedFamily; let totalCost; let employeeDetail; let MainId; let address;
      let beneficiary; let bankingDetailsDO; let checkInfoData; let addInfo; let indexPage;
      if (localStorage.getItem("selfCoverData")) selfCoverDatas = JSON.parse(localStorage.getItem("selfCoverData"));
      if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
      if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
      if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
      if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));
      if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      if (localStorage.getItem("MainId")) MainId = JSON.parse(localStorage.getItem("MainId"));
      if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));
      if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      if (localStorage.getItem("bankingDetailsDO")) bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));
      if (localStorage.getItem("checkInfoData")) checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));
      if (localStorage.getItem("addInfo")) addInfo = JSON.parse(localStorage.getItem("addInfo"));

      let payload = {
        page: 'add-info',
        sourceData: "funeralApp",
        representativeData,
        profile,
        selfCoverDatas,
        extendedFamily,
        indexPage,
        totalCost,
        address,
        employeeDetail,
        beneficiary,
        bankingDetailsDO,
        checkInfoData,
        addInfo,
        MainId
      }

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          setIsLoading(false);
          navigate("/signature-confirm");
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  }, [approved, navigate])

  const handleSubmit = () => {
    let reasons = {};
    if (affordableFunerral) reasons.affordableFunerral = affordableFunerral;
    if (funeralMyself) reasons.funeralMyself = funeralMyself;
    if (funeralMyFamily) reasons.funeralMyFamily = funeralMyFamily;
    if (funeralExtendedFamily) reasons.funeralExtendedFamily = funeralExtendedFamily;
    if (additionalBenifits) reasons.additionalBenifits = additionalBenifits;
    if (other) reasons.other = other;
    if (sendInfo) reasons.sendInfo = sendInfo

    localStorage.setItem('addInfo', JSON.stringify(reasons));
  };

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">REASONS FOR CHOOSING THE PLAN</h1>

        <Form noValidate>
          <div className="mx-auto px-3 pb-5">
            <Form.Check
              checked={affordableFunerral}
              type="checkbox"
              id="check1"
              label="AFFORDABLE FUNERAL COVER"
              value={"AFFORDABLE FUNERAL COVER"}
              onChange={(e) => { if (e.target.checked) setAffordableFunerral(e.target.value); else setAffordableFunerral(''); }}
            />

            <Form.Check
              checked={funeralMyself}
              type="checkbox"
              id="check2"
              label="FUNERAL COVER FOR MYSELF"
              value={"FUNERAL COVER FOR MYSELF"}
              onChange={(e) => { if (e.target.checked) setFuneralMyself(e.target.value); else setFuneralMyself(''); }}
            />
            <Form.Check
              checked={funeralMyFamily ? true : false}
              type="checkbox"
              id="check3"
              label="FUNERAL COVER FOR MY FAMILY"
              value={"FUNERAL COVER FOR MY FAMILY"}
              onChange={(e) => { if (e.target.checked) setFuneralMyFamily(e.target.value); else setFuneralMyFamily(''); }}
            />
            <Form.Check
              checked={funeralExtendedFamily ? true : false}
              type="checkbox"
              id="check4"
              label="FUNERAL COVER FOR MY EXTENDED FAMILY"
              value={"FUNERAL COVER FOR MY EXTENDED FAMILY"}
              onChange={(e) => { if (e.target.checked) setFuneralExtendedFamily(e.target.value); else setFuneralExtendedFamily(''); }}
            />
            <Form.Check
              checked={additionalBenifits ? true : false}
              type="checkbox"
              id="check5"
              label="ADDITIONAL BENIFITS"
              value={"ADDITIONAL BENIFITS"}
              onChange={(e) => { if (e.target.checked) setAdditionalBenifits(e.target.value); else setAdditionalBenifits(''); }}
            />
            <Form.Check
              checked={other ? true : false}
              type="checkbox"
              id="check6"
              label="I AM CONFIDENT THAT THE FAIR TREATMENT OF POLICY HOLDERS IS CENTRAL TO THE INSURER’S CULTURE"
              value={"I AM CONFIDENT THAT THE FAIR TREATMENT OF POLICY HOLDERS IS CENTRAL TO THE INSURER’S CULTURE"}
              onChange={(e) => { if (e.target.checked) setOther(e.target.value); else setOther(''); }}
            />

          </div>
          <Form.Group>
            <Form.Label htmlFor="rankTitle">May we in future send you information regarding Shield Life’s products and benefits</Form.Label>
            <Form.Select
              required
              aria-describedby="inputGroupPrepend"
              aria-label="RankTitle"
              value={sendInfo}
              onChange={(e) => setSendInfo(e.target.value)}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>

            </Form.Select>
          </Form.Group>

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/check-info"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button variant="primary" onClick={(e) => { handleSubmit(e); setApproved(true) }}>
                  NEXT
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default AddInfo;
